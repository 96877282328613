@keyframes show {
  from {
    visibility: hidden;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

.InternetStatus {
  box-sizing: border-box;
  position: fixed;
  bottom: 1em;
  left: 50%;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 1em;
  border-radius: 10px;
  color: white;
  background-color: #312525;
  z-index: 9999;
  transform: translateX(-50%);
  animation: show 500ms;
}

.InternetStatus svg {
  flex-shrink: 0;
  margin-right: 1em;
}
