html,
body {
  padding: 0;
  margin: 0;
}

hr {
  border-color: #506f85;
  border-width: 0.5px;
}

.p-0 {
  padding: 0;
}

.p-2 {
  padding: 1rem;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

/* */

.text-center {
  text-align: center;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-4 {
  margin-top: 2rem;
}

.text-gray {
  color: #4d4d4d;
}

.text-yellow {
  color: #b65c1c;
}

.text-red {
  color: #e20612;
}

.success {
  color: #007c00;
}

.text-italic {
  font-style: italic;
}

.text-regular {
  font-weight: normal;
}

.hintText {
  font-size: 14px;
  margin-top: 4px;
  display: inline-block;
}

.errorText {
  color: #e20612;
  font-size: 14px;
  margin-top: 4px;
  display: inline-block;
}

.errorInput {
  border-color: rgba(226, 6, 18, 0.5) !important;
  background-color: rgba(226, 6, 18, 0.1);
  border-radius: 3px;
}

.errorMessage {
  margin: 0 1rem;
  font-weight: 500;
  color: #e20612;
}

.main.container {
  padding: 0;
}

@media (max-width: 1220px) and (min-width: 768px) {
  .main.container {
    padding: 0 20px;
  }
}
/*----------------------------------------------*/

/* safe-area-inset-* variables fallbacks */
.header {
  top: 0;
}

.main {
  margin-top: 180px;
}

@media (max-width: 1024px) {
  .call-to-action {
    bottom: 5px;
  }
}

@media (max-width: 768px) {
  .main {
    margin-top: 140px;
  }
}

.header {
  position: fixed;
  top: env(safe-area-inset-top);
  left: 0;
  right: 0;
  z-index: 100;
}

.header::before {
  content: ' ';
  display: block;
  position: fixed;
  top: 0;
  height: env(safe-area-inset-top);
  width: 100%;
  background-color: #f9f9f9;
}

.main {
  margin-top: calc(180px + env(safe-area-inset-top));
  min-height: calc(100vh - 485px); /* - header height (180) - footer height */
}

input[type='number'] {
  -moz-appearance: textfield;
}

@media (max-width: 1024px) {
  .main {
    min-height: calc(100vh - 575px);
    margin-top: calc(140px + env(safe-area-inset-top));
  }

  .call-to-action {
    position: fixed;
    bottom: calc(5px + env(safe-area-inset-bottom));
    right: 10px;
    z-index: 103;
  }

  .call-to-action > button {
    min-width: 120px;
    font-size: 16px;
    padding: 0.75em 0.75em;
  }

  .hide-md {
    display: none;
  }
}

body {
  background-color: #f9f9f9;
}

html,
.main,
.App {
  background-color: white;
}

h2.BlockHeader {
  font-size: 22px;
  font-weight: 600;
}

@media (max-width: 992px) {
  h2.BlockHeader {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  h2.BlockHeader {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  h2.BlockHeader {
    font-size: 16px;
  }
}

/* ios */
@media only screen and (orientation: landscape) and (min-width: 320px) and (max-width: 812px) {
  html {
    -webkit-text-size-adjust: none;
  }
}
